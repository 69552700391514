var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row page-title-header"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"page-header"},[_c('b-breadcrumb',{staticClass:"m-0"},[_c('b-breadcrumb-item',{attrs:{"to":{
              name: 'DashboardHome',
            }}},[_c('i',{staticClass:"fa fa-home"})]),_c('b-breadcrumb-item',{attrs:{"to":{
              name: 'PointLogList',
            }}},[_vm._v("點數管理")]),_c('b-breadcrumb-item',{attrs:{"to":{
              name: 'PointLogList',
            }}},[_vm._v("點數異動紀錄")]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v("點數內容")])],1)],1)])]),_c('b-card',[_c('div',{staticClass:"row mb-2 align-items-end"},[_c('div',{staticClass:"col-8 d-flex items-center"},[_c('h4',{staticClass:"font-weight-bold"},[_vm._v("點數內容")])])]),_c('b-overlay',{attrs:{"show":_vm.isLoading,"rounded":"","opacity":"0.6","spinner-small":"","spinner-variant":"primary"}},[_c('section',{staticClass:"row mb-8"},[_c('b-table-simple',{attrs:{"hover":"","small":"","caption-top":"","responsive":"","col-12":"","col-xl-8":""}},[_c('b-tbody',[_c('b-tr',[_c('b-th',[_vm._v("會員名稱")]),_c('b-td',[_vm._v(_vm._s(_vm.form.customer_name))])],1),_c('b-tr',[_c('b-th',[_vm._v("點數")]),_c('b-td',[_vm._v(_vm._s(_vm.form.point))])],1),_c('b-tr',[_c('b-th',[_vm._v("已使用點數")]),_c('b-td',[_vm._v(_vm._s(_vm.form.used_point))])],1),_c('b-tr',[_c('b-th',[_vm._v("訂單編號")]),_c('b-td',[(!!_vm.form.order_id)?_c('b-link',{attrs:{"to":{
                    name: 'OrderDetail',
                    params: {
                      order_id: _vm.form.order_id,
                    },
                  }}},[_vm._v(_vm._s(_vm.form.order_number))]):_c('span',[_vm._v(_vm._s(_vm.form.order_number))])],1)],1),_c('b-tr',[_c('b-th',[_vm._v("備註")]),_c('b-td',[_vm._v(_vm._s(_vm.form.memo))])],1),_c('b-tr',[_c('b-th',[_vm._v("點數有效日/截止日")]),_c('b-td',[_vm._v(_vm._s(_vm.form.period))])],1),_c('b-tr',[_c('b-th',[_vm._v("建立日期")]),_c('b-td',[_vm._v(_vm._s(_vm.form.created_at))])],1)],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }