<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item
              :to="{
                name: 'PointLogList',
              }"
              >點數管理</b-breadcrumb-item
            >
            <b-breadcrumb-item
              :to="{
                name: 'PointLogList',
              }"
              >點數異動紀錄</b-breadcrumb-item
            >
            <b-breadcrumb-item active>點數內容</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
      <div class="row mb-2 align-items-end">
        <div class="col-8 d-flex items-center">
          <h4 class="font-weight-bold">點數內容</h4>
        </div>
      </div>

      <b-overlay
        :show="isLoading"
        rounded
        opacity="0.6"
        spinner-small
        spinner-variant="primary"
      >
        <section class="row mb-8">
          <b-table-simple hover small caption-top responsive col-12 col-xl-8>
            <b-tbody>
              <b-tr>
                <b-th>會員名稱</b-th>
                <b-td>{{ form.customer_name }}</b-td>
              </b-tr>
              <b-tr>
                <b-th>點數</b-th>
                <b-td>{{ form.point }}</b-td>
              </b-tr>
              <b-tr>
                <b-th>已使用點數</b-th>
                <b-td>{{ form.used_point }}</b-td>
              </b-tr>
              <b-tr>
                <b-th>訂單編號</b-th>
                <b-td>
                  <b-link
                    :to="{
                      name: 'OrderDetail',
                      params: {
                        order_id: form.order_id,
                      },
                    }"
                    v-if="!!form.order_id"
                    >{{ form.order_number }}</b-link
                  >
                  <span v-else>{{ form.order_number }}</span></b-td
                >
              </b-tr>
              <b-tr>
                <b-th>備註</b-th>
                <b-td>{{ form.memo }}</b-td>
              </b-tr>
              <b-tr>
                <b-th>點數有效日/截止日</b-th>
                <b-td>{{ form.period }}</b-td>
              </b-tr>
              <b-tr>
                <b-th>建立日期</b-th>
                <b-td>{{ form.created_at }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </section>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import pointLogApi from "../../../apis/point-log";

export default {
  data() {
    return {
      isLoading: false,
      form: {
        customer_name: null,
        point: null,
        used_point: null,
        order_id: null,
        order_number: null,
        memo: null,
        period: null,
        created_at: null,
      },
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    pointLogId() {
      return this.$route.params.pointLogId;
    },
    merchantId() {
      return this.$route.params.merchant_id
    }
  },
  mounted() {
    this.fetchPointLog();
  },
  methods: {
    async fetchPointLog() {
      try {
        this.isLoading = true;
        const { data } = await pointLogApi.getPoint(this.merchantId, this.pointLogId);
        const {
          customer_name,
          point,
          used_point,
          order_id,
          order_number,
          outer_order_number,
          memo,
          start_at,
          expired_at,
          created_at,
        } = data.data;

        this.form = {
          customer_name,
          point,
          used_point,
          order_id,
          order_number: `${order_number || ""}\n${
            outer_order_number || ""
          }`.trim(),
          memo,
          period: `${start_at || ""}\n${expired_at || ""}`.trim(),
          created_at,
        };
      } catch (error) {
        console.log("");
      }
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  white-space: pre-wrap;
  word-break: break-all;
}

th {
  width: 20%;
}
td {
  white-space: pre-wrap;
  word-break: break-all;
}
</style>
